<template>
  <div class="business-settings-component">
    <!-- BUTTONS -->
    <Buttons
      :btnCancel="false"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="onSubmit"
      :save_loading="save_loading"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- INFO USERS -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5>
                <strong>
                  {{ $t("SETTINGS.USER.PERSONAL.TITLE1") }}
                </strong>
              </h5>
            </b-col>
            <b-col lg="5" md="5" sm="12" cols="12">
              <v-text-field
                v-model="firstname"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.LNAME')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <b-col lg="5" md="5" sm="12" cols="12">
              <v-text-field
                v-model="lastname"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.FNAME')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <b-col lg="5" md="5" sm="12" cols="12">
              <v-text-field
                v-model="email"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.EMAIL')"
                :rules="validations.email"
                required
                outlined
                dense
                disabled
              />
            </b-col>
            <b-col lg="5" md="5" sm="12" cols="12">
              <v-text-field
                v-model="companyPosition"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.POSITION')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>
            <!-- LOGO -->
            <b-col lg="12" md="12" sm="12" cols="12">
              <Profile
                ref="imageProfile"
                :button_text="$t('SETTINGS.USER.PERSONAL.BTN.UPLOAD')"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- CHANGE PASSWORD -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <!-- Password -->
          <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5>
                <strong>
                  {{ $t("SETTINGS.USER.PERSONAL.TITLE2") }}
                </strong>
              </h5>
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <v-text-field
                v-model="password_current"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.CURRENT_PASS')"
                :rules="validations.password_current"
                type="password"
                outlined
                dense
              />
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <v-text-field
                v-model="password"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.NEW_PASS')"
                outlined
                type="password"
                dense
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.G_PASS") }}
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.EXAMPLE1") }}
                    </span>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.B_PASS") }}
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.EXAMPLE2") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <v-text-field
                v-model="password_repeat"
                :label="$t('SETTINGS.USER.PERSONAL.FIELDS.CO_NEW_PASS')"
                :rules="validations.password_repeat"
                type="password"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.G_PASS") }}
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.EXAMPLE1") }}
                    </span>
                    <span class="d-block">
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.B_PASS") }}
                      {{ $t("SETTINGS.USER.PERSONAL.FIELDS.EXAMPLE2") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </b-col>
          </b-row>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { UPDATE_USER } from "@/core/services/store/auth.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_CHANGING } from "@/core/services/store/breadcrumbs.module";
import { SET_PAGE_LOADED } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Buttons from "@/view/content/components/Buttons";
import Profile from "@/view/content/components/images/Profile";
import Rules from "@/core/services/utils/validations";

export default {
  components: {
    Buttons,
    Profile,
  },
  data() {
    return {
      start_modifiying: false,
      save_loading: false,
      valid: true,
      loader: false,
      firstname: null,
      lastname: null,
      email: null,
      photo: null,
      companyPosition: null,
      password: null,
      password_repeat: null,
      password_current: null,
      // VALIDATIONS
      validations: {
        valid: true,
        ...Rules,
        password_repeat: [
          () => {
            if (this.password !== null) {
              if (
                String(this.password.length) > 0 &&
                this.password_repeat === null
              ) {
                return this.$t("GENERAL.FIELD_ERROR");
              } else if (this.password_repeat !== null) {
                if (this.password !== this.password_repeat) {
                  return this.$t("GENERAL.PASSWORD_NOT_EQUALS");
                }
              }
            }
            return true;
          },
        ],
        password_current: [
          () => {
            if (this.password !== null) {
              if (
                String(this.password.length) > 0 &&
                this.password_current === null
              ) {
                return this.$t("GENERAL.FIELD_ERROR");
              }
            }
            return true;
          },
        ],
      },
    };
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;
        let data = {
          name: this.firstname,
          lastname: this.lastname,
          email: this.email,
          companyPosition: this.companyPosition,
          password: this.password,
          password_repeat: this.password_repeat,
          password_current: this.password_current,
        };

        const dataImage = await this.$refs.imageProfile.getData();
        if (dataImage.noImage) {
          this.photo = null;
        } else {
          this.photo = dataImage.url ? dataImage : this.photo;
        }

        data = {
          ...data,
          photoId: this.photo ? this.photo.fileId : null,
          photo: this.photo,
        };

        await this.$store.dispatch(UPDATE_USER, data).then(() => {
          this.save_loading = false;
          this.password = null;
          this.password_repeat = null;
          this.password_current = null;
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_PAGE_LOADED, false);
    this.$store.dispatch(SET_CHANGING, false);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SETTINGS.USER.MAIN.PROFILE") },
    ]);
    const { user } = this.currentUser;
    this.firstname = user.name;
    this.email = user.email;
    this.lastname = user.lastname;
    this.companyPosition = user.companyPosition;
    this.photo = user.photo ? user.photo : null;
    this.$refs.imageProfile.setData(this.photo);
  },
  computed: {
    ...mapGetters(["page_loaded", "currentUser"]),
    isLoader() {
      return this.loader;
    },
  },
  updated() {
    setTimeout(() => {
      this.$store.dispatch(SET_PAGE_LOADED, true);
    }, 3000);
  },
  watch: {
    $data: {
      handler: function() {
        if (this.page_loaded) {
          this.$store.dispatch(SET_CHANGING, true);
        }
      },
      deep: true,
    },
  },
};
</script>
